.signin_container {
    width: 100%;
    min-width: 100vh;
    background-color:#eee;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.signin_form_container{
    width: 900px;
    height: 500px;
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 /20%),
    0px 3px 4px 0px rgb(0 0 0/ 14%), 0px 1px 8px 0px rgb(0 0 0/ 12%);
}

.left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:#000000;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 16px;
}

.left h1 {
    margin-top: 0;
    color: white;
    font-size: 35px;
    align-self: center;
}

.right {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form_container h1 {
    font-size: 40px;
    margin-top: 0;
}

.input {
    outline: none;
    border: none;
    width:370px;
    padding: 15px;
    border-radius: 10px;
    background-color: #edf5f3;
    margin: 5px 0;
    font-size: 14px;
}

.error_msg{
    width: 370px;
    padding: 15px;
    margin: 15px 0;
    font-size:  14px;
    background-color: #f34646;
    color: white;
    border-radius: 5px;
    text-align: center;
}

.btn_white,
.btn_green {
    border: none;
    outline: none;
    padding: 12px 0;
    background-color: white;
    border-radius: 20px;
    width: 180px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.btn_white {
    background-color: #000000;
    color: white;
    margin: 10px;
}

.btn_green{
    margin-top: 15px;
}