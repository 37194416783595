#root {
  font-family: sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 500px;
  margin: 50px auto;
  border-radius:8px;
  padding:16px;
}

.App h1 {
  text-align: center;
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}

.form input[type="text"]{
  padding: 10px;
  width: 85%;
  border: 1px solid #eee;
  border-radius: 6px;
}

.form button[type="submit"]{
  background-color: #008080;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
}

.form input[type="text"]:focus,
.form button[type="submit"]:focus {
  outline: none;
}

.todo-listItems {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.todo-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.item-content {
  width:60%;
}

.todo-item button {
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 8px;
}

.todo-item button:focus {
  outline: none;
}
.update-item {
  background-color: goldenrod;
  color: #fff;
}

.delete-item {
  background-color: red;
  color:#fff
}

.update-form {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.update-new-input{
  width: 70%;
  border-radius: 6px;
  border: 1px solid #eee;
  padding: 8px;
  outline: none;
}

.update-new-button{
  background-color: green;
  color: #fff;
  cursor: pointer;
}

.cancel-new-button{
  background-color: orange;
  color: #fff;
  cursor: pointer;
}
