.main_container{

}
.navbar {
    width: 100%;
    height: 70px;
    background-color:#000000;
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.navbar h1{
    color: white;
    font-size: 25px;
    margin-left: 20px;
}

.btn_white {
    border: none;
    outline: none;
    padding:12px 0;
    background-color: white;
    border-radius: 20px;
    width: 120px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    margin-right: 15px;
}